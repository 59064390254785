import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type {
  AreaGasto,
  CategoriaGasto,
  SubcategoriaGasto
} from '../../../interfaces/balance/parametros/categoriasGastos';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de facturación vigentes
 */
const getCategoriasGastos = async () => {
  return await axios
    .get<{ data: CategoriaGasto[] }>(`${PATH}/categorias-gastos`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de facturación vigentes
 */
const getSubcategoriasGastos = async () => {
  return await axios
    .get<{ data: SubcategoriaGasto[] }>(`${PATH}/subcategorias-gastos`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de areas de gastos disponibles
 */
const getAreasGastos = async () => {
  return await axios.get<{ data: AreaGasto[] }>(`${PATH}/areas-gastos`).then((response) => {
    return response.data.data;
  });
};

const CategoriasGastosService = {
  getAreasGastos,
  getCategoriasGastos,
  getSubcategoriasGastos
};

export default CategoriasGastosService;
