import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import TipoResultadoFinancieroService from '../../../../services/balance/parametros/tipoResultadoFinanciero.service';

type Entity = Awaited<
  ReturnType<typeof TipoResultadoFinancieroService.getTiposResultadosFinancieros>
>[number];

export const fetchTiposResultadosFinancieros = createAsyncThunk<Entity[]>(
  'tiposResultadosFinancieros/fetchTiposResultadosFinancieros',
  (_params, api) => {
    const { tiposResultadosFinancieros } = api.getState() as RootState;
    if (tiposResultadosFinancieros.entities.length)
      return Promise.resolve(tiposResultadosFinancieros.entities);
    return TipoResultadoFinancieroService.getTiposResultadosFinancieros();
  }
);

interface TiposIntangibleesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposIntangibleesState;

const tiposResultadosFinancierosSlice = createSlice({
  name: 'tiposResultadosFinancieros',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposResultadosFinancieros.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposResultadosFinancieros.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposResultadosFinancieros.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const fetchTiposResultadosFinancierosActions = {
  ...tiposResultadosFinancierosSlice.actions,
  fetchTiposResultadosFinancieros
};

export default tiposResultadosFinancierosSlice.reducer;
