import type { RootState } from '../../../store';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import GastosIncluidosService from '../../../../services/lote/parametros/gastosIncluidos.service';

type Entity = Awaited<ReturnType<typeof GastosIncluidosService.getGastosIncluidos>>[number];

export const fetchGastosIncluidos = createAsyncThunk<Entity[]>(
  'gastosIncluidos/fetchGastosIncluidosStatus',
  (_params, api) => {
    const { gastosIncluidos } = api.getState() as RootState;
    if (gastosIncluidos.entities.length) return Promise.resolve(gastosIncluidos.entities);
    return GastosIncluidosService.getGastosIncluidos();
  }
);

interface GastosIncluidosState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as GastosIncluidosState;

const gastosIncluidosSlice = createSlice({
  name: 'gastosIncluidos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGastosIncluidos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchGastosIncluidos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchGastosIncluidos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const gastosIncluidosActions = { ...gastosIncluidosSlice.actions, fetchGastosIncluidos };

export default gastosIncluidosSlice.reducer;
