import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import BienesDeUsoService from '../../../../services/balance/parametros/bienesDeUso.service';

// Interfaces
import type { RootState } from '../../../store';

import type {
  CategoriaBienDeUso,
  SubcategoriaBienDeUso
} from '../../../../interfaces/balance/parametros/bienesDeUso';

type Entity = Awaited<ReturnType<typeof BienesDeUsoService.getSubcategoriasBienesDeUso>>[number];

export const fetchSubcategoriasBienDeUso = createAsyncThunk<Entity[]>(
  'subcategoriaBienesDeUso/fetchSubcategoriasBienDeUso',
  (_params, api) => {
    const { subcategoriaBienesDeUso } = api.getState() as RootState;
    if (subcategoriaBienesDeUso.entities.length)
      return Promise.resolve(subcategoriaBienesDeUso.entities);
    return BienesDeUsoService.getSubcategoriasBienesDeUso();
  }
);

interface SubcategoriaBienesDeUsoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  areas: [],
  entities: [],
  loading: true
} as SubcategoriaBienesDeUsoState;

const subcategoriaBienesDeUsoSlice = createSlice({
  name: 'subcategoriaBienesDeUso',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasBienDeUso.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSubcategoriasBienDeUso.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubcategoriasBienDeUso.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriaBienesDeUsoActions = {
  ...subcategoriaBienesDeUsoSlice.actions,
  fetchSubcategoriasBienDeUso
};

type CategoriaBienDeUsoConSubcategoria = CategoriaBienDeUso & {
  subcategorias: SubcategoriaBienDeUso[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasBienesDeUsoConSubcategorias = createSelector(
  (state: RootState) => state.subcategoriaBienesDeUso.entities,
  (subcategoriaBienesDeUso) => {
    const categories = uniqBy(
      subcategoriaBienesDeUso.map(
        (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaBienDeUsoConSubcategoria)
      ),
      'id'
    );

    categories.forEach((category) => {
      category.subcategorias = subcategoriaBienesDeUso.filter(
        (sc) => sc.categoria?.id === category?.id
      );
    });

    const orderedCategories = orderBy(
      orderBy(categories, ['orden'], ['asc']),
      ['descripcion'],
      ['asc']
    );
    return orderedCategories.filter((a) => a) as CategoriaBienDeUsoConSubcategoria[];
  }
);

export default subcategoriaBienesDeUsoSlice.reducer;
