import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type {
  CategoriaIngresoPresupuesto,
  SubcategoriaIngresoPresupuesto
} from '../../../interfaces/presupuesto/parametros/categoriasIngresosPresupuesto';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de ingresos vigentes
 */
const getCategoriasIngresosPresupuesto = async () => {
  return await axios
    .get<{ data: CategoriaIngresoPresupuesto[] }>(`${PATH}/categoria-ingreso-presupuesto`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de ingresos vigentes
 */
const getSubcategoriasIngresosPresupuesto = async () => {
  return await axios
    .get<{ data: SubcategoriaIngresoPresupuesto[] }>(`${PATH}/subcategoria-ingreso-presupuesto`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasIngresosService = {
  getCategoriasIngresosPresupuesto,
  getSubcategoriasIngresosPresupuesto
};

export default CategoriasIngresosService;
