/* eslint-disable no-empty */
// Service
import LotesService from '../../../services/lote/lotes.service';
import ViajeService from '../../../services/lote/viajes.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { ViajeDetalle } from '../../../interfaces/lote/viajes';
import type { EstadiaTerminal } from '../../../interfaces/lote/estadiaTerminal';

const initialState: {
  error: string;
  loading: boolean;
  viaje: ViajeDetalle | null;
  estadiasTerminal: EstadiaTerminal[];
} = {
  error: '',
  viaje: null,
  loading: true,
  estadiasTerminal: []
};

export const fetchViaje = createAsyncThunk<ViajeDetalle, number>(
  'viajeDetalle/fetchViajeStatus',
  async (id, { rejectWithValue }) => {
    try {
      // dispatch(viajeDetalleSlice.actions.clearError);
      const viaje = await ViajeService.getViaje(id);

      try {
        const novedad = await LotesService.getNovedadesParaBuque(viaje.lote.id, viaje.buque.id);
        if (novedad) {
          viaje.buque.nombre = novedad.nombre;
          viaje.buque.OMI = novedad.OMI;
          viaje.buque.tipoBuque = novedad.tipoBuque;
          viaje.buque.pais = novedad.pais;
        }
      } catch (error) {}

      return viaje;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchEstadiasTerminales = createAsyncThunk<EstadiaTerminal[], number>(
  'viajeDetalle/fetchEstadiasTerminalesStatus',
  async (id, { rejectWithValue }) => {
    try {
      const estadias = await ViajeService.getViajeEstadiasTerminales(id);
      return estadias;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const viajeDetalleSlice = createSlice({
  name: 'viajeDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViaje.fulfilled, (state, action) => {
      state.viaje = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchViaje.pending, (state, action) => {
      if (!state.viaje || state.viaje.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchViaje.rejected, (state, error) => {
      state.loading = false; // @ts-ignore
      state.error = error.payload || '500';
    });

    builder.addCase(fetchEstadiasTerminales.fulfilled, (state, action) => {
      state.estadiasTerminal = action.payload;
      state.loading = false;
    });
  }
});

export const viajeDetalleActions = {
  fetchViaje,
  fetchEstadiasTerminales,
  ...viajeDetalleSlice.actions
};

export default viajeDetalleSlice.reducer;
