import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import BuquesService from '../../../services/lote/buques.service';
import PuertosService from '../../../services/puertos.service';

/** @description hace un request que recupera el número total de puertos pendientes de validación y actualiza el store. */
export const fetchPuertosPendientes = createAsyncThunk<number>(
  'pendientesCount/fetchPuertosPendientes',
  () => {
    return PuertosService.getPendientesCount();
  }
);

/** @description hace un request que recupera el número total de buques pendientes de validación y actualiza el store. */
export const fetchBuquesPendientes = createAsyncThunk<number>(
  'pendientesCount/fetchBuquesPendientes',
  () => {
    return BuquesService.getPendientesCount();
  }
);

interface PendientesCountState {
  loading: boolean;

  /** @description número total de buques pendientes de validación */
  buques_pendientes: number;

  /** @description número total de puertos pendientes de validación */
  puertos_pendientes: number;
}

const initialState = {
  loading: false,
  buques_pendientes: 0,
  puertos_pendientes: 0
} as PendientesCountState;

const pendientesCountSlice = createSlice({
  name: 'pendientesCount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPuertosPendientes.fulfilled, (state, action) => {
      state.puertos_pendientes = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPuertosPendientes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPuertosPendientes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchBuquesPendientes.fulfilled, (state, action) => {
      state.buques_pendientes = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchBuquesPendientes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchBuquesPendientes.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const pendientesCountActions = {
  ...pendientesCountSlice.actions,
  fetchPuertosPendientes,
  fetchBuquesPendientes
};

export default pendientesCountSlice.reducer;
