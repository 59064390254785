// Service
import SaldosService from '../../../services/saldo/saldo.service';
import CategoriasGastosService from '../../../services/saldo/parametros/categoriasGastosSaldo.service';
import CategoriasIngresosService from '../../../services/saldo/parametros/categoriasIngresosSaldo.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { GastoSaldo, IngresoSaldo, SaldoDetalle } from '../../../interfaces/saldo/saldo';
import type { SubcategoriaGastoSaldo } from '../../../interfaces/saldo/parametros/categoriasGastosSaldo';
import type { SubcategoriaIngresoSaldo } from '../../../interfaces/saldo/parametros/categoriasIngresosSaldo';

const initialState: {
  error: string;

  loading: boolean;
  saldo: SaldoDetalle | null;

  ingresos: IngresoSaldo[];
  loadingIngresos: boolean;

  gastos: GastoSaldo[];
  loadingGastos: boolean;
} = {
  error: '',
  saldo: null,
  loading: true,

  ingresos: [],
  loadingIngresos: true,

  gastos: [],
  loadingGastos: true
};

export const fetchSaldo = createAsyncThunk<SaldoDetalle, number>(
  'saldo/fetchSaldoStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await SaldosService.getSaldo(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchSaldoIngresos = createAsyncThunk<IngresoSaldo[], number>(
  'saldo/fetchSaldoIngresos',
  async (id, { rejectWithValue }) => {
    try {
      const promises = [
        SaldosService.getIngresosSaldo(id),
        CategoriasIngresosService.getSubcategoriasIngresosSaldo()
      ];

      const [saldo, categorias] = await Promise.all(promises);

      const ingresosSaldo = saldo as { data: IngresoSaldo[] };
      const subcategorias = categorias as SubcategoriaIngresoSaldo[];

      const items = ingresosSaldo.data;

      subcategorias.forEach((subcategoria) => {
        const categoria = items.find((ingreso) => ingreso.ingreso.id === subcategoria.id);

        if (!categoria) {
          const item = {
            monto: 0,
            id: Math.random(),
            ingreso: subcategoria
          } as IngresoSaldo;
          items.push(item);
        }
      });

      return items;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchSaldoGastos = createAsyncThunk<GastoSaldo[], number>(
  'saldo/fetchSaldoGastos',
  async (id, { rejectWithValue }) => {
    try {
      const promises = [
        SaldosService.getGastosSaldo(id),
        CategoriasGastosService.getSubcategoriasGastosSaldo()
      ];

      const [saldo, categorias] = await Promise.all(promises);

      const ingresosSaldo = saldo as { data: GastoSaldo[] };
      const subcategorias = categorias as SubcategoriaGastoSaldo[];

      const items = ingresosSaldo.data;

      subcategorias.forEach((subcategoria) => {
        const categoria = items.find((ingreso) => ingreso.gasto.id === subcategoria.id);

        if (!categoria) {
          const item = {
            monto: 0,
            id: Math.random(),
            gasto: subcategoria
          } as GastoSaldo;
          items.push(item);
        }
      });

      return items;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const saldoDetalleSlice = createSlice({
  name: 'saldoDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.saldo = null;
      state.loading = true;
      state.ingresos = [];
      state.loadingIngresos = true;
      state.gastos = [];
      state.loadingGastos = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSaldo.fulfilled, (state, action) => {
      state.saldo = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchSaldo.pending, (state, action) => {
      if (!state.saldo || state.saldo.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchSaldo.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });

    builder.addCase(fetchSaldoIngresos.fulfilled, (state, action) => {
      state.ingresos = action.payload;
      state.loadingIngresos = false;
    });

    builder.addCase(fetchSaldoIngresos.pending, (state) => {
      state.loadingIngresos = true;
    });

    builder.addCase(fetchSaldoGastos.fulfilled, (state, action) => {
      state.gastos = action.payload;
      state.loadingGastos = false;
    });

    builder.addCase(fetchSaldoGastos.pending, (state) => {
      state.loadingGastos = true;
    });
  }
});

export const saldoDetalleActions = {
  fetchSaldo,
  ...saldoDetalleSlice.actions
};

export default saldoDetalleSlice.reducer;
