import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { CajaBanco } from '../../../interfaces/balance/parametros/cajaBanco';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de tipos de caja banco
 */
const getTiposCajaBanco = async () => {
  return await axios.get<{ data: CajaBanco[] }>(`${PATH}/tipos-cajas-banco`).then((response) => {
    return response.data.data;
  });
};

const TipoCajaBancoService = {
  getTiposCajaBanco
};

export default TipoCajaBancoService;
