import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { VariablePresupuesto } from '../../../interfaces/presupuesto/parametros/variablePresupuesto';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de gastos vigentes
 */
const getVariablesPresupuesto = async () => {
  return await axios
    .get<{ data: VariablePresupuesto[] }>(`${PATH}/presupuestos-variables`)
    .then((response) => {
      return response.data.data;
    });
};

const VariablesPresupuestoService = {
  getVariablesPresupuesto
};

export default VariablesPresupuestoService;
