import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';

// Services
import CategoriasIngresosService from '../../../../services/saldo/parametros/categoriasIngresosSaldo.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaIngresoSaldo,
  SubcategoriaIngresoSaldo
} from '../../../../interfaces/saldo/parametros/categoriasIngresosSaldo';

type Entity = Awaited<
  ReturnType<typeof CategoriasIngresosService.getSubcategoriasIngresosSaldo>
>[number];

export const fetchsubcategoriaIngresoSaldo = createAsyncThunk<Entity[]>(
  'subcategoriasIngresoSaldo/fetchsubcategoriaIngresoSaldoStatus',
  (_params, api) => {
    const { subcategoriaIngresosSaldo } = api.getState() as RootState;
    if (subcategoriaIngresosSaldo.entities.length)
      return Promise.resolve(subcategoriaIngresosSaldo.entities);
    return CategoriasIngresosService.getSubcategoriasIngresosSaldo();
  }
);

interface subcategoriaIngresoSaldoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as subcategoriaIngresoSaldoState;

const subcategoriaIngresosSaldoSlice = createSlice({
  name: 'subcategoriaIngresosSaldo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchsubcategoriaIngresoSaldo.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchsubcategoriaIngresoSaldo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchsubcategoriaIngresoSaldo.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriaIngresosSaldoActions = {
  ...subcategoriaIngresosSaldoSlice.actions,
  fetchsubcategoriaIngresoSaldo
};

type CategoriaIngresoConSubcategoria = CategoriaIngresoSaldo & {
  subcategorias: SubcategoriaIngresoSaldo[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasIngresoSaldoConSubcategorias = (state: RootState) => {
  const {
    subcategoriaIngresosSaldo: { entities: subcategoriasIngresoSaldo }
  } = state;

  const categories = uniqBy(
    subcategoriasIngresoSaldo.map(
      (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaIngresoConSubcategoria)
    ),
    'id'
  );

  categories.forEach((category) => {
    category.subcategorias = subcategoriasIngresoSaldo.filter(
      (sc) => sc.categoria?.id === category?.id
    );
  });

  const orderedCategories = orderBy(
    orderBy(categories, ['orden'], ['asc']),
    ['descripcion'],
    ['asc']
  );

  return orderedCategories.filter((a) => a) as CategoriaIngresoConSubcategoria[];
};

export default subcategoriaIngresosSaldoSlice.reducer;
