// Service
import PresupuestosService from '../../../services/presupuesto/presupuesto.service';
import CategoriasGastosService from '../../../services/presupuesto/parametros/categoriasGastosPresupuesto.service';
import CategoriasIngresosService from '../../../services/presupuesto/parametros/categoriasIngresosPresupuesto.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type {
  GastoPresupuesto,
  IngresoPresupuesto,
  PresupuestoDetalle
} from '../../../interfaces/presupuesto/presupuesto';
import type { SubcategoriaGastoPresupuesto } from '../../../interfaces/presupuesto/parametros/categoriasGastosPresupuesto';
import type { SubcategoriaIngresoPresupuesto } from '../../../interfaces/presupuesto/parametros/categoriasIngresosPresupuesto';

const initialState: {
  error: string;

  loadingIngresos: boolean;
  ingresos: IngresoPresupuesto[];

  loadingGastos: boolean;
  gastos: GastoPresupuesto[];

  loading: boolean;
  presupuesto: PresupuestoDetalle | null;
} = {
  error: '',
  loading: true,
  presupuesto: null,

  gastos: [],
  loadingGastos: true,

  ingresos: [],
  loadingIngresos: true
};

export const fetchPresupuesto = createAsyncThunk<PresupuestoDetalle, number>(
  'presupuesto/fetchPresupuestoStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await PresupuestosService.getPresupuesto(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchPresupuestoIngresos = createAsyncThunk<IngresoPresupuesto[], number>(
  'presupuesto/fetchPresupuestoIngresos',
  async (id, { rejectWithValue }) => {
    try {
      const promises = [
        PresupuestosService.getIngresosPresupuesto(id),
        CategoriasIngresosService.getSubcategoriasIngresosPresupuesto()
      ];

      const [presupuesto, categorias] = await Promise.all(promises);

      const ingresosPresupuesto = presupuesto as { data: IngresoPresupuesto[] };
      const subcategorias = categorias as SubcategoriaIngresoPresupuesto[];

      const items = ingresosPresupuesto.data;

      subcategorias.forEach((subcategoria) => {
        const categoria = items.find((ingreso) => ingreso.ingreso.id === subcategoria.id);

        if (!categoria) {
          const item = {
            monto: 0,
            id: Math.random(),
            ingreso: subcategoria
          } as IngresoPresupuesto;
          items.push(item);
        }
      });

      return items;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchPresupuestoGastos = createAsyncThunk<GastoPresupuesto[], number>(
  'presupuesto/fetchPresupuestoGastos',
  async (id, { rejectWithValue }) => {
    try {
      const promises = [
        PresupuestosService.getGastosPresupuesto(id),
        CategoriasGastosService.getSubcategoriasGastosPresupuesto()
      ];

      const [presupuesto, categorias] = await Promise.all(promises);

      const ingresosPresupuesto = presupuesto as { data: GastoPresupuesto[] };
      const subcategorias = categorias as SubcategoriaGastoPresupuesto[];

      const items = ingresosPresupuesto.data;

      subcategorias.forEach((subcategoria) => {
        const categoria = items.find((ingreso) => ingreso.gasto.id === subcategoria.id);

        if (!categoria) {
          const item = {
            monto: 0,
            id: Math.random(),
            gasto: subcategoria
          } as GastoPresupuesto;
          items.push(item);
        }
      });

      return items;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const presupuestoDetalleSlice = createSlice({
  name: 'presupuestoDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.presupuesto = null;
      state.loading = true;
      state.loadingGastos = true;
      state.loadingIngresos = true;
      state.ingresos = [];
      state.gastos = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPresupuesto.fulfilled, (state, action) => {
      state.presupuesto = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchPresupuesto.pending, (state, action) => {
      if (!state.presupuesto || state.presupuesto.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchPresupuesto.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });

    builder.addCase(fetchPresupuestoGastos.pending, (state) => {
      state.loadingGastos = true;
    });

    builder.addCase(fetchPresupuestoGastos.fulfilled, (state, action) => {
      state.gastos = action.payload;
      state.loadingGastos = false;
    });

    builder.addCase(fetchPresupuestoIngresos.fulfilled, (state, action) => {
      state.ingresos = action.payload;
      state.loadingIngresos = false;
    });

    builder.addCase(fetchPresupuestoIngresos.pending, (state) => {
      state.loadingIngresos = true;
    });
  }
});

export const presupuestoDetalleActions = {
  fetchPresupuesto,
  fetchPresupuestoGastos,
  fetchPresupuestoIngresos,
  ...presupuestoDetalleSlice.actions
};

export default presupuestoDetalleSlice.reducer;
