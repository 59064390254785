import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { TipoIntangible } from '../../../interfaces/balance/parametros/tipoIntangible';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de tipos de intangibles
 */
const getTiposIntangibles = async () => {
  return await axios
    .get<{ data: TipoIntangible[] }>(`${PATH}/tipos-intangibles`)
    .then((response) => {
      return response.data.data;
    });
};

const TipoIntangiblesService = {
  getTiposIntangibles
};

export default TipoIntangiblesService;
