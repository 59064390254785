import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createCompressor from 'redux-persist-transform-compress';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import authReducer from './slices/auth';
import paisesReducer from './slices/paises';

import mapReducer from './slices/puertos/map';
import puertosReducer from './slices/puertos/puertos';
import puertoDetalleReducer from './slices/puertos/puertoDetalle';
import pendientesCountReducer from './slices/puertos/pendientesCount';

import loteDetalleReducer from './slices/lote/loteDetalle';
import viajeDetalleReducer from './slices/lote/viajeDetalle';
import intentoImportacionReducer from './slices/lote/intentoImportacion';

import navierasReducer from './slices/lote/parametros/navieras';
import productosReducer from './slices/lote/parametros/productos';
import tiposBuqueReducer from './slices/lote/parametros/tiposBuque';
import transportesReducer from './slices/lote/parametros/transporte';
import contenedoresReducer from './slices/lote/parametros/contenedores';
import agenteMaritimoReducer from './slices/lote/parametros/agenteMaritimo';
import tiposOperacionesReducer from './slices/lote/parametros/tipoOperacion';
import tiposNavegacionReducer from './slices/lote/parametros/tiposNavegacion';
import gastosIncluidosReducer from './slices/lote/parametros/gastosIncluidos';
import modosDeExplotacionReducer from './slices/lote/parametros/modoExplotacion';
import categoriasFacturacionReducer from './slices/lote/parametros/categoriasFacturacion';
import modalidadesFletamientoReducer from './slices/lote/parametros/modalidadFletamiento';
import subcategoriasFacturacionReducer from './slices/lote/parametros/subcategoriaFacturacion';
import contenidoConteinerizadoDetalleReducer from './slices/lote/contenidoConteinerizadoDetalle';

import saldoDetalleReducer from './slices/saldo/saldoDetalle';
import subcategoriaGastosSaldoReducer from './slices/saldo/parametros/subcategoriaGastosSaldo';
import subcategoriaIngresosSaldoReducer from './slices/saldo/parametros/subcategoriaIngresosSaldo';

import balanceDetalleReducer from './slices/balance/balanceDetalle';
import tiposCajaBancoReducer from './slices/balance/parametros/tiposCajaBanco';
import tiposCuentasBceReducer from './slices/balance/parametros/tiposCuentasBce';
import tiposInversionesReducer from './slices/balance/parametros/tiposInversiones';
import tiposIntangiblesReducer from './slices/balance/parametros/tiposIntangibles';
import subcategoriasGastosReducer from './slices/balance/parametros/subcategoriaGastos';
import subcategoriasIngresosReducer from './slices/balance/parametros/subcategoriaIngresos';
import tiposMonedasExtranjerasReducer from './slices/balance/parametros/tiposMonedaExtranjera';
import subcategoriaBienesDeUsoReducer from './slices/balance/parametros/subcategoriaBienesDeUso';
import tiposResultadosFinancierosReducer from './slices/balance/parametros/tiposResultadosFinancieros';

import presupuestoDetalleReducer from './slices/presupuesto/presupuestoDetalle';
import variablesPresupuestoReducer from './slices/presupuesto/parametros/variablesPresupuesto';
import subcategoriaGastosPresupuestoReducer from './slices/presupuesto/parametros/subcategoriaGastosPresupuesto';
import subcategoriaIngresosPresupuestoReducer from './slices/presupuesto/parametros/subcategoriaIngresosPresupuesto';

const appReducer = combineReducers({
  map: mapReducer,
  auth: authReducer,
  paises: paisesReducer,
  puertos: puertosReducer,
  navieras: navierasReducer,
  productos: productosReducer,
  tiposBuque: tiposBuqueReducer,
  transportes: transportesReducer,
  loteDetalle: loteDetalleReducer,
  contenedores: contenedoresReducer,
  viajeDetalle: viajeDetalleReducer,
  puertoDetalle: puertoDetalleReducer,
  agenteMaritimos: agenteMaritimoReducer,
  gastosIncluidos: gastosIncluidosReducer,
  pendientesCount: pendientesCountReducer,
  tiposNavegacion: tiposNavegacionReducer,
  tiposOperaciones: tiposOperacionesReducer,
  intentoImportacion: intentoImportacionReducer,
  modosDeExplotacion: modosDeExplotacionReducer,
  categoriasFacturacion: categoriasFacturacionReducer,
  modalidadesFletamiento: modalidadesFletamientoReducer,
  subcategoriasFacturacion: subcategoriasFacturacionReducer,
  contenidoConteinerizadoDetalle: contenidoConteinerizadoDetalleReducer,

  saldoDetalle: saldoDetalleReducer,
  subcategoriaGastosSaldo: subcategoriaGastosSaldoReducer,
  subcategoriaIngresosSaldo: subcategoriaIngresosSaldoReducer,

  balanceDetalle: balanceDetalleReducer,
  tiposCajaBanco: tiposCajaBancoReducer,
  tiposCuentasBce: tiposCuentasBceReducer,
  tiposInversiones: tiposInversionesReducer,
  tiposIntangibles: tiposIntangiblesReducer,
  subcategoriasGastos: subcategoriasGastosReducer,
  subcategoriasIngresos: subcategoriasIngresosReducer,
  subcategoriaBienesDeUso: subcategoriaBienesDeUsoReducer,
  tiposMonedasExtranjeras: tiposMonedasExtranjerasReducer,
  tiposResultadosFinancieros: tiposResultadosFinancierosReducer,

  presupuestoDetalle: presupuestoDetalleReducer,
  variablesPresupuesto: variablesPresupuestoReducer,
  subcategoriaGastosPresupuesto: subcategoriaGastosPresupuestoReducer,
  subcategoriaIngresosPresupuesto: subcategoriaIngresosPresupuestoReducer
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'auth/removeUser') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// TODO: Reactivar cache
const whitelist = [
  'auth',
  'paises',
  'puertos',
  'navieras',
  'tiposBuque',
  'transportes',
  'gastosIncluidos',
  'agenteMaritimos',
  'tiposNavegacion',
  'tiposOperaciones',
  'modosDeExplotacion',
  'categoriasFacturacion',
  'modalidadesFletamiento',
  'subcategoriasFacturacion'
] as string[];

const compressor = createCompressor();

const persistedReducer = persistReducer(
  {
    storage,
    whitelist,
    key: 'root',
    transforms: [
      compressor,
      ...whitelist
        .filter((s) => s !== 'auth')
        .map((s) => expireReducer(s, { expireSeconds: 60 * 30 }))
    ]
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'map/setMapRef']
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
