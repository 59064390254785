import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type {
  CategoriaGastoSaldo,
  SubcategoriaGastoSaldo
} from '../../../interfaces/saldo/parametros/categoriasGastosSaldo';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de gastos vigentes
 */
const getCategoriasGastosSaldo = async () => {
  return await axios
    .get<{ data: CategoriaGastoSaldo[] }>(`${PATH}/categoria-gasto-saldo`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de gastos vigentes
 */
const getSubcategoriasGastosSaldo = async () => {
  return await axios
    .get<{ data: SubcategoriaGastoSaldo[] }>(`${PATH}/subcategoria-gasto-saldo`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasGastosService = {
  getCategoriasGastosSaldo,
  getSubcategoriasGastosSaldo
};

export default CategoriasGastosService;
