import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { TipoNavegacion } from '../../../interfaces/lote/parametros/tipoNavegacion';

/**
 * @description Recupera desde la API el listado de tipos de navegación
 * @returns {Promise<TipoNavegacion[]>}
 */
const getTiposNavegacion = async () => {
  return await axios
    .get<{ data: TipoNavegacion[] }>(`${API_URL}/tipos-navegacion`)
    .then((response) => {
      return response.data.data;
    });
};

const TiposNavegacionService = {
  getTiposNavegacion
};

export default TiposNavegacionService;
