import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import CajaBancoService from '../../../../services/balance/parametros/cajaBanco.service';

type Entity = Awaited<ReturnType<typeof CajaBancoService.getTiposCajaBanco>>[number];

export const fetchTiposCajaBanco = createAsyncThunk<Entity[]>(
  'tiposCajaBanco/fetchTiposCajaBanco',
  (_params, api) => {
    const { tiposCajaBanco } = api.getState() as RootState;
    if (tiposCajaBanco.entities.length) return Promise.resolve(tiposCajaBanco.entities);
    return CajaBancoService.getTiposCajaBanco();
  }
);

interface TiposCajaBancoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposCajaBancoState;

const tiposCajaBancoSlice = createSlice({
  name: 'tiposCajaBanco',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposCajaBanco.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposCajaBanco.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposCajaBanco.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposCajaBancoActions = {
  ...tiposCajaBancoSlice.actions,
  fetchTiposCajaBanco
};

export default tiposCajaBancoSlice.reducer;
