import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { MonedaExtranjera } from '../../../interfaces/balance/parametros/monedaExtranjera';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de monedas extranjeras.
 */
const getMonedasExtranjeras = async () => {
  return await axios
    .get<{ data: MonedaExtranjera[] }>(`${PATH}/tipos-monenda-extranjera`)
    .then((response) => {
      return response.data.data;
    });
};

const MonedaExtranjeraService = {
  getMonedasExtranjeras
};

export default MonedaExtranjeraService;
