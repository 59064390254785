import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { GastoIncluido } from '../../../interfaces/lote/parametros/gastosIncluidos';

/**
 * @description Recupera desde la API el listado de gastos incluidos vigentes
 */
const getGastosIncluidos = async () => {
  return await axios
    .get<{ data: GastoIncluido[] }>(`${API_URL}/gastos-incluidos`)
    .then((response) => {
      return response.data.data;
    });
};

const GastosIncluidosService = {
  getGastosIncluidos
};

export default GastosIncluidosService;
