import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import CategoriasGastoService from '../../../../services/balance/parametros/categoriasGastos.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  AreaGasto,
  CategoriaGasto,
  SubcategoriaGasto
} from '../../../../interfaces/balance/parametros/categoriasGastos';

type Entity = Awaited<ReturnType<typeof CategoriasGastoService.getSubcategoriasGastos>>[number];

export const fetchSubcategoriasGasto = createAsyncThunk<Entity[]>(
  'subcategoriasGasto/fetchSubcategoriasGastoStatus',
  (_params, api) => {
    const { subcategoriasGastos } = api.getState() as RootState;
    if (subcategoriasGastos.entities.length) return Promise.resolve(subcategoriasGastos.entities);
    return CategoriasGastoService.getSubcategoriasGastos();
  }
);

export const fetchAreasGasto = createAsyncThunk<AreaGasto[]>(
  'subcategoriasGasto/fetchAreasGasto',
  (_params, api) => {
    const { subcategoriasGastos } = api.getState() as RootState;
    if (subcategoriasGastos.areas.length) return Promise.resolve(subcategoriasGastos.areas);
    return CategoriasGastoService.getAreasGastos();
  }
);

interface SubcategoriasGastoState {
  loading: boolean;
  entities: Entity[];
  areas: AreaGasto[];
}

const initialState = {
  areas: [],
  entities: [],
  loading: true
} as SubcategoriasGastoState;

const subcategoriasGastoSlice = createSlice({
  name: 'subcategoriasGastos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasGasto.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSubcategoriasGasto.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubcategoriasGasto.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAreasGasto.fulfilled, (state, action) => {
      state.areas = action.payload;
    });

    builder.addCase(fetchAreasGasto.pending, (state) => {
      state.areas = [];
    });
  }
});

export const subcategoriasGastoActions = {
  ...subcategoriasGastoSlice.actions,
  fetchSubcategoriasGasto,
  fetchAreasGasto
};

type CategoriaGastoConSubcategoria = CategoriaGasto & {
  subcategorias: SubcategoriaGasto[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasGastoConSubcategorias = createSelector(
  (state: RootState) => state.subcategoriasGastos.entities,
  (subcategoriasGasto) => {
    const categories = uniqBy(
      subcategoriasGasto.map(
        (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaGastoConSubcategoria)
      ),
      'id'
    );

    categories.forEach((category) => {
      category.subcategorias = subcategoriasGasto.filter((sc) => sc.categoria?.id === category?.id);
    });

    const orderedCategories = orderBy(
      orderBy(categories, ['orden'], ['asc']),
      ['descripcion'],
      ['asc']
    );
    return orderedCategories.filter((a) => a) as CategoriaGastoConSubcategoria[];
  }
);

export default subcategoriasGastoSlice.reducer;
