import axios from '../../config/axios';
import { API_URL } from '../../config';

import type { TipoContenedor } from '../../interfaces/lote/parametros/contenedor';

/**
 * @description Recupera desde la API el listado de tipos de contenedores
 */
const getTiposDeContenedores = async () => {
  return await axios
    .get<{ data: TipoContenedor[] }>(`${API_URL}/tipos-contenedor`)
    .then((response) => {
      return response.data.data;
    });
};

const TiposContenedoresService = {
  getTiposDeContenedores
};

export default TiposContenedoresService;
