import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type {
  CategoriaIngreso,
  SubcategoriaIngreso
} from '../../../interfaces/balance/parametros/categoriasIngresos';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de facturación vigentes
 */
const getCategoriasIngresos = async () => {
  return await axios
    .get<{ data: CategoriaIngreso[] }>(`${PATH}/categorias-ingresos`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de facturación vigentes
 */
const getSubcategoriasIngresos = async () => {
  return await axios
    .get<{ data: SubcategoriaIngreso[] }>(`${PATH}/subcategorias-ingresos`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasIngresosService = {
  getCategoriasIngresos,
  getSubcategoriasIngresos
};

export default CategoriasIngresosService;
