import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ProductosService from '../../../../services/lote/parametros/productos.service';
import { RootState } from '../../../store';

type Entity = Awaited<ReturnType<typeof ProductosService.getProductos>>[number];

export const fetchProductos = createAsyncThunk<Entity[]>(
  'productos/fetchProductos',
  (_params, api) => {
    const { productos } = api.getState() as RootState;
    if (productos.entities.length) return Promise.resolve(productos.entities);
    return ProductosService.getProductos();
  }
);

interface ProductoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as ProductoState;

const productosSlice = createSlice({
  name: 'productos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchProductos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProductos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const productosActions = { ...productosSlice.actions, fetchProductos };

export default productosSlice.reducer;
