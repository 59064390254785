import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TiposBuqueService from '../../../../services/lote/parametros/tiposBuque.service';
import type { RootState } from '../../../store';

type Entity = Awaited<ReturnType<typeof TiposBuqueService.getTiposBuques>>[number];

export const fetchTiposBuque = createAsyncThunk<Entity[]>(
  'tiposBuque/fetchTiposBuquesStatus',
  (_params, api) => {
    const { tiposBuque } = api.getState() as RootState;
    if (tiposBuque.entities.length) return Promise.resolve(tiposBuque.entities);
    return TiposBuqueService.getTiposBuques();
  }
);

interface TiposBuqueState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposBuqueState;

const tiposBuqueSlice = createSlice({
  name: 'tiposBuque',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposBuque.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposBuque.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposBuque.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposBuqueActions = { ...tiposBuqueSlice.actions, fetchTiposBuque };

export default tiposBuqueSlice.reducer;
