import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import CategoriasGastoSaldoService from '../../../../services/saldo/parametros/categoriasGastosSaldo.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaGastoSaldo,
  SubcategoriaGastoSaldo
} from '../../../../interfaces/saldo/parametros/categoriasGastosSaldo';

type Entity = Awaited<
  ReturnType<typeof CategoriasGastoSaldoService.getSubcategoriasGastosSaldo>
>[number];

export const fetchSubcategoriasGastoSaldo = createAsyncThunk<Entity[]>(
  'subcategoriaGastosSaldo/fetchSubcategoriasGastoSaldo',
  (_params, api) => {
    const { subcategoriaGastosSaldo } = api.getState() as RootState;
    if (subcategoriaGastosSaldo.entities.length)
      return Promise.resolve(subcategoriaGastosSaldo.entities);
    return CategoriasGastoSaldoService.getSubcategoriasGastosSaldo();
  }
);

interface SubcategoriasGastoSaldoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  areas: [],
  entities: [],
  loading: true
} as SubcategoriasGastoSaldoState;

const subcategoriasGastoSaldoSlice = createSlice({
  name: 'subcategoriaGastosSaldo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasGastoSaldo.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSubcategoriasGastoSaldo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubcategoriasGastoSaldo.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriasGastoSaldoActions = {
  ...subcategoriasGastoSaldoSlice.actions,
  fetchSubcategoriasGastoSaldo
};

type CategoriaGastoConSubcategoria = CategoriaGastoSaldo & {
  subcategorias: SubcategoriaGastoSaldo[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasGastoSaldoConSubcategorias = createSelector(
  (state: RootState) => state.subcategoriaGastosSaldo.entities,
  (subcategoriasGasto) => {
    const categories = uniqBy(
      subcategoriasGasto.map(
        (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaGastoConSubcategoria)
      ),
      'id'
    );

    categories.forEach((category) => {
      category.subcategorias = subcategoriasGasto.filter((sc) => sc.categoria?.id === category?.id);
    });

    const orderedCategories = orderBy(
      orderBy(categories, ['orden'], ['asc']),
      ['descripcion'],
      ['asc']
    );
    return orderedCategories.filter((a) => a) as CategoriaGastoConSubcategoria[];
  }
);

export default subcategoriasGastoSaldoSlice.reducer;
