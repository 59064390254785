import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import TipoIntangibleService from '../../../../services/balance/parametros/tipoIntangible.service';

type Entity = Awaited<ReturnType<typeof TipoIntangibleService.getTiposIntangibles>>[number];

export const fetchTiposIntangibles = createAsyncThunk<Entity[]>(
  'tiposIntangibles/fetchTiposIntangibles',
  (_params, api) => {
    const { tiposIntangibles } = api.getState() as RootState;
    if (tiposIntangibles.entities.length) return Promise.resolve(tiposIntangibles.entities);
    return TipoIntangibleService.getTiposIntangibles();
  }
);

interface TiposIntangibleesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposIntangibleesState;

const tiposIntangiblesSlice = createSlice({
  name: 'tiposIntangibles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposIntangibles.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposIntangibles.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposIntangibles.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const fetchTiposIntangiblesActions = {
  ...tiposIntangiblesSlice.actions,
  fetchTiposIntangibles
};

export default tiposIntangiblesSlice.reducer;
