/* eslint-disable react/react-in-jsx-scope */
import { Button, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FC, memo, useCallback, useRef } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LoginFormPage, ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Services
import AuthService from '../../../services/auth.service';

// Interfaces
import type { LoginParams } from './interfaces';

// Constants
import { PAGE_TITLE } from '../../../constants/page';
import { PRIMARY_COLOR } from '../../../constants/colors';

import './styles.scss';

export const LoginPage: FC = memo(() => {
  const ref = useRef<ProFormInstance<LoginParams>>();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: LoginParams) => {
      return await AuthService.login(data.email.trim(), data.password)
        .then(() => {
          navigate('/');
          return true;
        })
        .catch((e) => {
          if (e && e.response && e.response.data && e.response.data.message === 'Unauthorized.') {
            //   // ref.current?.setFields([{ name: 'email', errors: ['Email o contraseña incorrecta'] }]);
            notification.error({
              closeIcon: false,
              placement: 'bottomLeft',
              description: 'El email y la contraseña provista parecen no ser válidos.',
              message: 'Error'
            });
          }

          return false;
        });
    },
    [navigate]
  );

  return (
    <div className="login-page-container">
      <LoginFormPage<LoginParams>
        formRef={ref}
        title="Ingresar"
        onFinish={onSubmit}
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div style={{ marginTop: -15, marginBottom: 10 }}>
                  <Link
                    style={{
                      float: 'right',
                      color: '#595959'
                    }}
                    to="/olvide-contraseña"
                  >
                    Olvidé mi contraseña
                  </Link>
                </div>

                <Button {...props.submitButtonProps} type="primary" onClick={props.submit}>
                  Confirmar
                </Button>

                <img
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.svg`}
                />
              </div>
            );
          }
        }}
        subTitle={<div className="login-page-subtitle">{PAGE_TITLE} </div>}
        backgroundImageUrl={`${process.env.PUBLIC_URL}/login_background1.jpg`}
        activityConfig={{
          style: {
            color: '#fff',
            maxWidth: 500,
            borderRadius: 8,
            backgroundColor: PRIMARY_COLOR,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)'
          },
          title: 'Te damos la bienvenida',
          subTitle:
            'En caso de no contar con credenciales de acceso, será necesario solicitarlas al administrador.'
        }}
      >
        <div>
          <div>
            <ProFormText
              name="email"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className={'prefixIcon'} />
              }}
              placeholder={'Email'}
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: 'Debe completar este campo'
                },
                {
                  type: 'email',
                  message: 'Ingrese un email válido'
                }
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />
              }}
              placeholder={'Contraseña'}
              rules={[
                {
                  required: true,
                  message: 'Debe completar este campo'
                }
              ]}
            />
          </div>
        </div>
      </LoginFormPage>
    </div>
  );
});

LoginPage.displayName = 'LoginPage';

export default LoginPage;
