import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import CategoriasIngresoService from '../../../../services/balance/parametros/categoriasIngresos.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaIngreso,
  SubcategoriaIngreso
} from '../../../../interfaces/balance/parametros/categoriasIngresos';

type Entity = Awaited<ReturnType<typeof CategoriasIngresoService.getSubcategoriasIngresos>>[number];

export const fetchSubcategoriasIngreso = createAsyncThunk<Entity[]>(
  'subcategoriasIngreso/fetchSubcategoriasIngresoStatus',
  (_params, api) => {
    const { subcategoriasIngresos } = api.getState() as RootState;
    if (subcategoriasIngresos.entities.length)
      return Promise.resolve(subcategoriasIngresos.entities);
    return CategoriasIngresoService.getSubcategoriasIngresos();
  }
);

interface SubcategoriasIngresoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as SubcategoriasIngresoState;

const subcategoriasIngresoSlice = createSlice({
  name: 'subcategoriasIngresos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasIngreso.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSubcategoriasIngreso.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubcategoriasIngreso.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriasIngresoActions = {
  ...subcategoriasIngresoSlice.actions,
  fetchSubcategoriasIngreso
};

type CategoriaIngresoConSubcategoria = CategoriaIngreso & {
  subcategorias: SubcategoriaIngreso[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasIngresoConSubcategorias = (state: RootState) => {
  const {
    subcategoriasIngresos: { entities: subcategoriasIngreso }
  } = state;

  const categories = uniqBy(
    subcategoriasIngreso.map(
      (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaIngresoConSubcategoria)
    ),
    'id'
  );

  categories.forEach((category) => {
    category.subcategorias = subcategoriasIngreso.filter((sc) => sc.categoria?.id === category?.id);
  });

  const orderedCategories = orderBy(
    orderBy(categories, ['orden'], ['asc']),
    ['descripcion'],
    ['asc']
  );

  return orderedCategories.filter((a) => a) as CategoriaIngresoConSubcategoria[];
};

export default subcategoriasIngresoSlice.reducer;
