// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import TiposContenedoresService from '../../../../services/lote/contenedor.service';

// Interfaces
import type { RootState } from '../../../store';

type Entity = Awaited<ReturnType<typeof TiposContenedoresService.getTiposDeContenedores>>[number];

export const fetchContenedores = createAsyncThunk<Entity[]>(
  'contenedores/fetchContenedores',
  (_params, api) => {
    const { contenedores } = api.getState() as RootState;
    if (contenedores.entities.length) return Promise.resolve(contenedores.entities);
    return TiposContenedoresService.getTiposDeContenedores();
  }
);

interface ContenedoresState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as ContenedoresState;

const contenedoresSlice = createSlice({
  name: 'contenedores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContenedores.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchContenedores.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchContenedores.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const contenedoresActions = { ...contenedoresSlice.actions, fetchContenedores };

export default contenedoresSlice.reducer;
