import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { Naviera } from '../../../interfaces/lote/parametros/naviera';

/**
 * @description Recupera desde la API el listado de navieras vigentes
 * @returns {Promise<Naviera[]>}
 */
const getNavieras = async () => {
  return await axios.get<{ data: Naviera[] }>(`${API_URL}/navieras`).then((response) => {
    return response.data.data;
  });
};

const NavierasService = {
  getNavieras
};

export default NavierasService;
