import axios from '../../config/axios';

import { API_URL } from '../../config';

import type {
  Presupuesto,
  PresupuestoDetalle,
  NewPresupuestoFormData,
  PresupuestosQueryParams,
  EditPresupuestoFormData
} from '../../interfaces/presupuesto/presupuesto';

import type {
  EstadoPresupuesto,
  EstadoPresupuestoQueryParams
} from '../../interfaces/presupuesto/presupuesto';

import type {
  IngresoPresupuesto,
  IngresoPresupuestoQueryParams,
  NewIngresoPresupuestoFormData,
  EditIngresoPresupuestoFormData
} from '../../interfaces/presupuesto/presupuesto';

import type {
  GastoPresupuesto,
  GastoPresupuestoQueryParams,
  NewGastoPresupuestoFormData,
  EditGastoPresupuestoFormData
} from '../../interfaces/presupuesto/presupuesto';

import type {
  MacroPresupuesto,
  NewMacroPresupuestoFormData,
  EditMacroPresupuestoFormData,
  MacroPresupuestoQueryParams
} from '../../interfaces/presupuesto/presupuesto';

import type { PaginatedResponse } from '../../interfaces/api';

const BASE_URL = `${API_URL}/contable`;

/* ---------------------------------------
                  PRESUPUESTOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de lotes de presupuestos que puede ver el usuario
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Presupuesto>`.
 */
const getPresupuestos = async (params: PresupuestosQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Presupuesto>>(BASE_URL + '/presupuestos', { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el lote de presupuesto solicitado
 * @param loteId id del lote a recuperar
 * @returns Un objeto de tipo `PresupuestoDetalle`.
 * @throws si el usuario no tiene permisos para ver el lote de presupuesto.
 * @throws si el lote no existe.
 */
const getPresupuesto = async (loteId: number) => {
  return await axios
    .get<{ data: PresupuestoDetalle }>(`${BASE_URL}/presupuestos/${loteId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo lote de presupuesto
 * @param data datos del lote a crear
 * @returns Un objeto de tipo `PresupuestoDetalle`.
 * @throws si el usuario no tiene permisos para crear el lote.
 */
const newPresupuesto = async (data: NewPresupuestoFormData) => {
  return await axios.post<PresupuestoDetalle>(BASE_URL + '/presupuestos', data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza en la api los datos de lote de presupuesto
 * @param loteId id del lote a editar
 * @param data datos del lote a editar
 * @returns Un objeto de tipo `PresupuestoDetalle`.
 * @throws si el usuario no tiene permisos para editar el lote.
 */
const editPresupuesto = async (loteId: number, data: EditPresupuestoFormData) => {
  return await axios
    .patch<PresupuestoDetalle>(`${BASE_URL}/presupuestos/${loteId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el lote de presupuesto indicado (soft-delete)
 * @param loteId id del lote a eliminar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para eliminar el lote.
 * @throws si el lote no existe.
 * @throws si lote no está en estado borrador.
 */
const deletePresupuesto = async (loteId: number) => {
  return await axios.delete<Presupuesto>(`${BASE_URL}/presupuestos/${loteId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
          ESTADOS DE PRESUPUESTOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de estados de lotes de presupuesto que puede ver el usuario
 */
const getPresupuestosEstados = async (params: EstadoPresupuestoQueryParams) => {
  return await axios
    .get<{ data: EstadoPresupuesto[] }>(`${BASE_URL}/presupuestos-estados`, { params })
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
      INGRESOS DE PRESUPUESTOS
 ---------------------------------------- */

const INGRESOS_PRESUPUESTO_URL = `${BASE_URL}/ingresos-presupuesto` as const;

/**
 * @description Recupera desde la API el listado de ingresos de presupuesto que puede ver el usuario
 * @param presupuestoId id del balance
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<IngresoPresupuesto>`.
 */
const getIngresosPresupuesto = async (
  presupuestoId: number,
  params: IngresoPresupuestoQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<IngresoPresupuesto>>(`${INGRESOS_PRESUPUESTO_URL}`, {
      params: { ...params, presupuesto_id: presupuestoId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el ingreso de presupuesto solicitado
 * @param ingresoId id del ingreso a recuperar
 * @returns Un objeto de tipo `IngresoPresupuesto`.
 * @throws si el usuario no tiene permisos para ver el ingreso de presupuesto.
 * @throws si el ingreso no existe.
 */
const getIngresoPresupuesto = async (ingresoId: number) => {
  return await axios
    .get<{ data: IngresoPresupuesto }>(`${INGRESOS_PRESUPUESTO_URL}/${ingresoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo ingreso de presupuesto
 * @param presupuestoId id del balance
 * @param data datos del ingreso a crear
 * @returns Un objeto de tipo `IngresoPresupuesto`.
 * @throws si el usuario no tiene permisos para crear el ingreso.
 */
const newIngresoPresupuesto = async (
  presupuestoId: number,
  data: NewIngresoPresupuestoFormData
) => {
  return await axios
    .post<IngresoPresupuesto>(`${INGRESOS_PRESUPUESTO_URL}`, {
      ...data,
      presupuesto_id: presupuestoId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos del ingreso de presupuesto
 * @param ingresoId id del ingreso a editar
 * @param data datos del ingreso a editar
 * @returns Un objeto de tipo `IngresoPresupuesto`.
 * @throws si el usuario no tiene permisos para editar el ingreso.
 */
const editIngresoPresupuesto = async (ingresoId: number, data: EditIngresoPresupuestoFormData) => {
  return await axios
    .patch<IngresoPresupuesto>(`${INGRESOS_PRESUPUESTO_URL}/${ingresoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el ingreso de presupuesto indicado (soft-delete)
 * @param ingresoId id del ingreso a eliminar
 * @returns Un objeto de tipo `IngresoPresupuesto`.
 * @throws si el usuario no tiene permisos para eliminar el ingreso.
 * @throws si el ingreso no existe.
 */
const deleteIngresoPresupuesto = async (ingresoId: number) => {
  return await axios
    .delete<IngresoPresupuesto>(`${INGRESOS_PRESUPUESTO_URL}/${ingresoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
            GASTOS DE PRESUPUESTOS
 ---------------------------------------- */

const GASTOS_PRESUPUESTO_URL = `${BASE_URL}/gastos-presupuesto` as const;

/**
 * @description Recupera desde la API el listado de gastos de presupuesto que puede ver el usuario
 * @param presupuestoId id del balance
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<GastoPresupuesto>`.
 */
const getGastosPresupuesto = async (
  presupuestoId: number,
  params: GastoPresupuestoQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<GastoPresupuesto>>(`${GASTOS_PRESUPUESTO_URL}`, {
      params: { ...params, presupuesto_id: presupuestoId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el gasto de presupuesto solicitado
 * @param gastoId id del gasto a recuperar
 * @returns Un objeto de tipo `GastoPresupuesto`.
 * @throws si el usuario no tiene permisos para ver el gasto de presupuesto.
 * @throws si el gasto no existe.
 */
const getGastoPresupuesto = async (gastoId: number) => {
  return await axios
    .get<{ data: GastoPresupuesto }>(`${GASTOS_PRESUPUESTO_URL}/${gastoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo gasto de presupuesto
 * @param presupuestoId id del balance
 * @param data datos del gasto a crear
 * @returns Un objeto de tipo `GastoPresupuesto`.
 * @throws si el usuario no tiene permisos para crear el gasto.
 */
const newGastoPresupuesto = async (presupuestoId: number, data: NewGastoPresupuestoFormData) => {
  return await axios
    .post<GastoPresupuesto>(`${GASTOS_PRESUPUESTO_URL}`, { ...data, presupuesto_id: presupuestoId })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos del gasto de presupuesto
 * @param gastoId id del gasto a editar
 * @param data datos del gasto a editar
 * @returns Un objeto de tipo `GastoPresupuesto`.
 * @throws si el usuario no tiene permisos para editar el gasto.
 */
const editGastoPresupuesto = async (gastoId: number, data: EditGastoPresupuestoFormData) => {
  return await axios
    .patch<GastoPresupuesto>(`${GASTOS_PRESUPUESTO_URL}/${gastoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el gasto de presupuesto indicado (soft-delete)
 * @param gastoId id del gasto a eliminar
 * @returns Un objeto de tipo `GastoPresupuesto`.
 * @throws si el usuario no tiene permisos para eliminar el gasto.
 * @throws si el gasto no existe.
 */
const deleteGastoPresupuesto = async (gastoId: number) => {
  return await axios
    .delete<GastoPresupuesto>(`${GASTOS_PRESUPUESTO_URL}/${gastoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
      MACRO PRESUPUESTOS
 ---------------------------------------- */

const MACRO_PRESUPUESTO_URL = `${BASE_URL}/presupuestos-macros` as const;
/**
 * @description Recupera desde la API el listado de macro presupuestos que puede ver el usuario
 * @param presupuestoId id del presupuesto
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<MacroPresupuesto>`.
 */
const getMacrosPresupuesto = async (
  presupuestoId: number,
  params: MacroPresupuestoQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<MacroPresupuesto>>(`${MACRO_PRESUPUESTO_URL}`, {
      params: { ...params, presupuesto_id: presupuestoId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el macro presupuesto solicitado
 * @param macroId id del macro presupuesto a recuperar
 * @returns Un objeto de tipo `MacroPresupuesto`.
 * @throws si el usuario no tiene permisos para ver el macro presupuesto.
 * @throws si el macro presupuesto no existe.
 */
const getMacroPresupuesto = async (macroId: number) => {
  return await axios
    .get<{ data: MacroPresupuesto }>(`${MACRO_PRESUPUESTO_URL}/${macroId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo macro presupuesto
 * @param presupuestoId id del presupuesto
 * @param data datos del macro presupuesto a crear
 * @returns Un objeto de tipo `MacroPresupuesto`.
 * @throws si el usuario no tiene permisos para crear el macro presupuesto.
 */
const newMacroPresupuesto = async (presupuestoId: number, data: NewMacroPresupuestoFormData) => {
  return await axios
    .post<MacroPresupuesto>(`${MACRO_PRESUPUESTO_URL}`, { ...data, presupuesto_id: presupuestoId })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos del macro presupuesto
 * @param macroId id del macro presupuesto a editar
 * @param data datos del macro presupuesto a editar
 * @returns Un objeto de tipo `MacroPresupuesto`.
 * @throws si el usuario no tiene permisos para editar el macro presupuesto.
 */
const editMacroPresupuesto = async (macroId: number, data: EditMacroPresupuestoFormData) => {
  return await axios
    .patch<MacroPresupuesto>(`${MACRO_PRESUPUESTO_URL}/${macroId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el macro presupuesto indicado (soft-delete)
 * @param macroId id del macro presupuesto a eliminar
 * @returns Un objeto de tipo `MacroPresupuesto`.
 * @throws si el usuario no tiene permisos para eliminar el macro presupuesto.
 * @throws si el macro presupuesto no existe.
 */
const deleteMacroPresupuesto = async (macroId: number) => {
  return await axios
    .delete<MacroPresupuesto>(`${MACRO_PRESUPUESTO_URL}/${macroId}`)
    .then((response) => {
      return response.data;
    });
};

export const PresupuestoService = {
  getPresupuestos,
  getPresupuesto,
  newPresupuesto,
  editPresupuesto,
  deletePresupuesto,

  getPresupuestosEstados,

  getIngresosPresupuesto,
  getIngresoPresupuesto,
  newIngresoPresupuesto,
  editIngresoPresupuesto,
  deleteIngresoPresupuesto,

  getGastosPresupuesto,
  getGastoPresupuesto,
  newGastoPresupuesto,
  editGastoPresupuesto,
  deleteGastoPresupuesto,

  getMacrosPresupuesto,
  getMacroPresupuesto,
  newMacroPresupuesto,
  editMacroPresupuesto,
  deleteMacroPresupuesto
};

export default PresupuestoService;
