import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TipoCuentasBceService from '../../../../services/balance/parametros/tipoCuentaBce.service';

// Interfaces
import type { RootState } from '../../../store';

type Entity = Awaited<ReturnType<typeof TipoCuentasBceService.getTiposCuentasBce>>[number];

export const fetchTiposCuentasBce = createAsyncThunk<Entity[]>(
  'tiposCuentasBce/fetchTiposCuentasBce',
  (_params, api) => {
    const { tiposCuentasBce } = api.getState() as RootState;
    if (tiposCuentasBce.entities.length) return Promise.resolve(tiposCuentasBce.entities);
    return TipoCuentasBceService.getTiposCuentasBce();
  }
);

interface TiposCuentasBceState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposCuentasBceState;

const tiposCuentasBceSlice = createSlice({
  name: 'tiposCuentasBce',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposCuentasBce.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposCuentasBce.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposCuentasBce.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposCuentasBceActions = {
  ...tiposCuentasBceSlice.actions,
  fetchTiposCuentasBce
};

export default tiposCuentasBceSlice.reducer;
