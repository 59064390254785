import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type {
  CategoriaBienDeUso,
  SubcategoriaBienDeUso
} from '../../../interfaces/balance/parametros/bienesDeUso';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de bienes de uso vigentes
 */
const getCategoriasBienesDeUso = async () => {
  return await axios
    .get<{ data: CategoriaBienDeUso[] }>(`${PATH}/categorias-bienes-de-uso`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de bienes de uso vigentes
 */
const getSubcategoriasBienesDeUso = async () => {
  return await axios
    .get<{ data: SubcategoriaBienDeUso[] }>(`${PATH}/subcategorias-bienes-de-uso`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasBienesDeUsoService = {
  getCategoriasBienesDeUso,
  getSubcategoriasBienesDeUso
};

export default CategoriasBienesDeUsoService;
