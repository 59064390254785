import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ModalidadesFletamientoService from '../../../../services/lote/parametros/modalidadFletamiento.service';

// Interfaces
import { RootState } from '../../../store';
type Entity = Awaited<
  ReturnType<typeof ModalidadesFletamientoService.getModalidadesFletamiento>
>[number];

export const fetchModalidadesFletamiento = createAsyncThunk<Entity[]>(
  'modalidadesFletamiento/fetchAgentesMaritimoStatus',
  (_params, api) => {
    const { modalidadesFletamiento } = api.getState() as RootState;
    if (modalidadesFletamiento.entities.length)
      return Promise.resolve(modalidadesFletamiento.entities);
    return ModalidadesFletamientoService.getModalidadesFletamiento();
  }
);

interface ModalidadesFletamientoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as ModalidadesFletamientoState;

const modalidadesFletamientoSlice = createSlice({
  name: 'modalidadesFletamiento',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModalidadesFletamiento.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchModalidadesFletamiento.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchModalidadesFletamiento.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const modalidadesFletamientoActions = {
  ...modalidadesFletamientoSlice.actions,
  fetchModalidadesFletamiento
};

export default modalidadesFletamientoSlice.reducer;
