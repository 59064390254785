import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { orderBy, uniqBy } from 'lodash';
import CategoriasFacturacionService from '../../../../services/lote/parametros/categoriasFacturacion.service';

// Interfaces
import type { RootState } from '../../../store';
import type {
  CategoriaFacturacion,
  SubcategoriaFacturacion
} from '../../../../interfaces/lote/parametros/categoriasFacturacion';

type Entity = Awaited<
  ReturnType<typeof CategoriasFacturacionService.getSubcategoriasFacturacion>
>[number];

export const fetchSubcategoriasFacturacion = createAsyncThunk<Entity[]>(
  'subcategoriasFacturacion/fetchSubcategoriasFacturacionStatus',
  (_params, api) => {
    const { subcategoriasFacturacion } = api.getState() as RootState;
    if (subcategoriasFacturacion.entities.length)
      return Promise.resolve(subcategoriasFacturacion.entities);
    return CategoriasFacturacionService.getSubcategoriasFacturacion();
  }
);

interface SubcategoriasFacturacionState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as SubcategoriasFacturacionState;

const subcategoriasFacturacionSlice = createSlice({
  name: 'subcategoriasFacturacion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubcategoriasFacturacion.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSubcategoriasFacturacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubcategoriasFacturacion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const subcategoriasFacturacionActions = {
  ...subcategoriasFacturacionSlice.actions,
  fetchSubcategoriasFacturacion
};

type CategoriaFacturacionConSubcategoria = CategoriaFacturacion & {
  subcategorias: SubcategoriaFacturacion[];
};

/** @description retorna un listado de categorias con sus subcategorias */
export const getCategoriasFacturacionConSubcategorias = (state: RootState) => {
  const {
    subcategoriasFacturacion: { entities: subcategoriasFacturacion }
  } = state;

  const categories = uniqBy(
    subcategoriasFacturacion.map(
      (s) => ({ ...s.categoria, subcategorias: [] } as CategoriaFacturacionConSubcategoria)
    ),
    'id'
  );

  categories.forEach((category) => {
    category.subcategorias = subcategoriasFacturacion.filter(
      (sc) => sc.categoria?.id === category?.id
    );
  });

  const orderedCategories = orderBy(
    categories.filter((c) => c.descripcion !== 'Otros'),
    'descripcion'
  );
  const othersCategory = categories.find((c) => c.descripcion === 'Otros');

  return [...orderedCategories, othersCategory].filter(
    (a) => a
  ) as CategoriaFacturacionConSubcategoria[];
};

export default subcategoriasFacturacionSlice.reducer;
