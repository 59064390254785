import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../../store';

import TransporteService from '../../../../services/lote/parametros/transporte.service';

type Entity = Awaited<ReturnType<typeof TransporteService.getTiposTransporte>>[number];

export const fetchTransportes = createAsyncThunk<Entity[]>(
  'transportes/fetchTransportestatus',
  (_params, api) => {
    const { transportes } = api.getState() as RootState;
    if (transportes.entities.length) return Promise.resolve(transportes.entities);
    return TransporteService.getTiposTransporte();
  }
);

interface TransportesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TransportesState;

const transportesSlice = createSlice({
  name: 'transportes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransportes.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTransportes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTransportes.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const transportesActions = { ...transportesSlice.actions, fetchTransportes };

export default transportesSlice.reducer;
