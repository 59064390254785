import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import TipoInversionService from '../../../../services/balance/parametros/tipoInversion.service';

type Entity = Awaited<ReturnType<typeof TipoInversionService.getTiposInversion>>[number];

export const fetchTiposInversiones = createAsyncThunk<Entity[]>(
  'tiposInversiones/fetchTiposInversiones',
  (_params, api) => {
    const { tiposInversiones } = api.getState() as RootState;
    if (tiposInversiones.entities.length) return Promise.resolve(tiposInversiones.entities);
    return TipoInversionService.getTiposInversion();
  }
);

interface TiposInversionesState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposInversionesState;

const tiposInversionesSlice = createSlice({
  name: 'tiposInversiones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposInversiones.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposInversiones.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposInversiones.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const fetchTiposInversionesActions = {
  ...tiposInversionesSlice.actions,
  fetchTiposInversiones
};

export default tiposInversionesSlice.reducer;
