import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { ModalidadFletamiento } from '../../../interfaces/lote/parametros/modalidadesFletamiento';

/**
 * @description Recupera desde la API el listado de modalidades de fletamiento vigentes
 * @returns {Promise<ModalidadFletamiento[]>}
 */
const getModalidadesFletamiento = async () => {
  return await axios
    .get<{ data: ModalidadFletamiento[] }>(`${API_URL}/modalidades-fletamiento`)
    .then((response) => {
      return response.data.data;
    });
};

const ModalidadesFletamientoService = {
  getModalidadesFletamiento
};

export default ModalidadesFletamientoService;
