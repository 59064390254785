// Service
import ViajeService from '../../../services/lote/viajes.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type {
  ContenidoConteinerizadoViaje,
  TipoOperacionViaje
} from '../../../interfaces/lote/parametros/tiposOperaciones';

const initialState: {
  error: string;
  loading: boolean;
  operacion: TipoOperacionViaje | null;
  contenido: ContenidoConteinerizadoViaje | null;
} = {
  error: '',
  loading: true,
  operacion: null,
  contenido: null
};

export const fetchContenidoConteinerizable = createAsyncThunk<
  { operacion: TipoOperacionViaje; contenido: ContenidoConteinerizadoViaje },
  { viajeId: number; operacionId: number; contenidoId: number }
>(
  'contenidoConteinerizadoDetalle/fetchContenidoConteinerizableStatus',
  async ({ viajeId, operacionId, contenidoId }, { rejectWithValue }) => {
    try {
      const [contenido, operacion] = await Promise.all([
        ViajeService.getViajeOperacionConteinerizada(viajeId, operacionId, contenidoId),
        ViajeService.getViajeTipoOperacion(viajeId, operacionId)
      ]);

      return { contenido, operacion };
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const contenidoConteinerizadoDetalleSlice = createSlice({
  name: 'contenidoConteinerizadoDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContenidoConteinerizable.fulfilled, (state, action) => {
      state.contenido = action.payload.contenido;
      state.operacion = action.payload.operacion;
      state.loading = false;
    });

    builder.addCase(fetchContenidoConteinerizable.pending, (state, action) => {
      if (!state.contenido || state.contenido.id !== action.meta.arg.contenidoId)
        state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchContenidoConteinerizable.rejected, (state, error) => {
      state.loading = false; // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const contenidoConteinerizadoDetalleActions = {
  ...contenidoConteinerizadoDetalleSlice.actions,
  fetchContenidoConteinerizable
};

export default contenidoConteinerizadoDetalleSlice.reducer;
