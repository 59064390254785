// Interface
import { TipoCuentaBceBalance } from '../../../../interfaces/balance/tipoCuentaBceBalance';
import { TipoCajaBancoBalance } from '../../../../interfaces/balance/tipoCajaBancoBalance';
import { TipoInversionBalance } from '../../../../interfaces/balance/tipoInversionBalance';
import { groupBy } from 'lodash';
import { TipoCuentaBce } from '../../../../interfaces/balance/parametros/tipoCuentaBce';
import { BALANCE_DETALLE_SECTIONS } from '../../../../pages/balances/detalle/constants/sections';
import { TipoMonedaExtranjeraBalance } from '../../../../interfaces/balance/tipoMonedaExtranjeraBalance';
import { TipoBienUsoBalance } from '../../../../interfaces/balance/tipoBienUsoBalance';
import { TipoIntangibleBalance } from '../../../../interfaces/balance/tipoIntangibleBalance';

export interface BalanceEstadoBalanceChild {
  id: string;
  name: string;
  total: number;
  section: string;
  total_anterior: number;
}

export interface BalanceEstadoBalance {
  name: string;
  total: number;
  total_anterior: number;
  section?: string;
  items: BalanceEstadoBalanceChild[];
  color: 'green' | 'yellow' | 'orange';
}

const getCuentasByCategoria = (cuentas: TipoCuentaBceBalance[]) => {
  return groupBy(
    cuentas,
    (c) =>
      `${c.tipo_cuenta.es_pasivo ? 'Pasivos' : 'Activos'} ${
        c.tipo_cuenta.es_corriente ? 'Corrientes' : 'No Corrientes'
      }`
  );
};

export const getEstadoBalance = (
  cuentas: { data: TipoCuentaBceBalance[] },
  cajas: { data: TipoCajaBancoBalance[] },
  monedas: { data: TipoMonedaExtranjeraBalance[] },
  bienesUso: { data: TipoBienUsoBalance[] },
  inversiones: { data: TipoInversionBalance[] },
  intangibles: { data: TipoIntangibleBalance[] },
  parameters: { cuentasBce: TipoCuentaBce[] }
): BalanceEstadoBalance[] => {
  const results: BalanceEstadoBalance[] = [];

  const cuentasByCategoria = getCuentasByCategoria(cuentas.data);

  const cajasBancos = {
    name: 'Cajas y Bancos',
    total: cajas.data.reduce((acc, c) => acc + c.monto, 0),
    total_anterior: cajas.data.reduce((acc, c) => acc + c.monto_anterior, 0),
    section: BALANCE_DETALLE_SECTIONS.CAJAS_BANCOS
  } as BalanceEstadoBalanceChild;

  const monedaExtranjera = {
    name: 'Moneda Extranjera',
    total: monedas.data.reduce((acc, c) => acc + c.monto, 0),
    total_anterior: cajas.data.reduce((acc, c) => acc + c.monto_anterior, 0),
    section: BALANCE_DETALLE_SECTIONS.MONEDAS_EXTRANJERAS
  } as BalanceEstadoBalanceChild;

  const inversionesTransitorias = {
    name: 'Inversiones Transitorias',
    total: inversiones.data.reduce((acc, i) => acc + i.monto + i.renta, 0),
    total_anterior: inversiones.data.reduce((acc, i) => acc + i.monto_anterior, 0),
    section: BALANCE_DETALLE_SECTIONS.INVERSIONES
  } as BalanceEstadoBalanceChild;

  const bienesDeUso = bienesUso.data
    .filter((b) => b.bien_de_uso.descripcion.includes('Bienes de uso'))
    .reduce(
      (acc, b) => ({
        color: 'green',
        name: 'Bienes de Uso',
        total: acc.total + b.monto,
        id: b.bien_de_uso.id.toString(),
        section: BALANCE_DETALLE_SECTIONS.BIENES_DE_USO,
        total_anterior: acc.total_anterior + b.monto_anterior
      }),
      {
        total: 0,
        color: 'green',
        total_anterior: 0,
        name: 'Bienes de Uso',
        section: BALANCE_DETALLE_SECTIONS.BIENES_DE_USO,
        id: bienesUso.data
          .filter((b) => b.bien_de_uso.descripcion.includes('Bienes de uso'))[0]
          ?.id.toString()
      }
    );

  const intangible = intangibles.data.reduce(
    (acc, i) => ({
      color: 'green',
      name: 'Intangibles',
      total: acc.total + i.monto,
      id: i.tipo.id.toString(),
      section: BALANCE_DETALLE_SECTIONS.INTANGIBLES,
      total_anterior: acc.total_anterior + i.monto_anterior
    }),
    {
      total: 0,
      color: 'green',
      total_anterior: 0,
      name: 'Intangibles',
      section: BALANCE_DETALLE_SECTIONS.INTANGIBLES,
      id: intangibles.data[0]?.tipo.id.toString()
    }
  );

  const allActivosCorrientes = parameters.cuentasBce.filter((c) => !c.es_pasivo && c.es_corriente);
  const activosCorrientes = (cuentasByCategoria['Activos Corrientes'] || [])?.map((c) => ({
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: c.monto,
    id: c.id.toString(),
    name: c.tipo_cuenta.descripcion,
    total_anterior: c.monto_anterior
  }));

  allActivosCorrientes.forEach((cuenta) => {
    if (!activosCorrientes.some((ac) => ac.id === cuenta.id.toString())) {
      activosCorrientes.push({
        section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
        total: 0,
        id: cuenta.id.toString(),
        name: cuenta.descripcion,
        total_anterior: 0
      });
    }
  });

  results.push({
    name: 'Activos Corrientes',
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total:
      cajasBancos.total +
      monedaExtranjera.total +
      inversionesTransitorias.total +
      activosCorrientes.reduce((acc, c) => acc + c.total, 0),
    total_anterior:
      cajasBancos.total_anterior +
      monedaExtranjera.total_anterior +
      inversionesTransitorias.total_anterior +
      activosCorrientes.reduce((acc, c) => acc + c.total_anterior, 0),
    items: [cajasBancos, monedaExtranjera, inversionesTransitorias, ...activosCorrientes],
    color: 'yellow'
  });

  if (!cuentasByCategoria['Activos No Corrientes']) {
    cuentasByCategoria['Activos No Corrientes'] = [];
  }

  const allActivosNoCorrientes = parameters.cuentasBce.filter(
    (c) => !c.es_pasivo && !c.es_corriente
  );

  const activosNoCorrientes = cuentasByCategoria['Activos No Corrientes'].map((c) => ({
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: c.monto,
    id: c.id.toString(),
    name: c.tipo_cuenta.descripcion,
    total_anterior: c.monto_anterior
  }));

  allActivosNoCorrientes.forEach((cuenta) => {
    if (!activosNoCorrientes.some((ac) => ac.id === cuenta.id.toString())) {
      activosNoCorrientes.push({
        section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
        total: 0,
        id: cuenta.id.toString(),
        name: cuenta.descripcion,
        total_anterior: 0
      });
    }
  });

  results.push({
    name: 'Activos No Corrientes',
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total:
      intangible.total +
      activosNoCorrientes.reduce((acc, c) => acc + c.total, 0) +
      bienesDeUso.total,
    total_anterior:
      intangible.total_anterior +
      activosNoCorrientes.reduce((acc, c) => acc + c.total_anterior, 0) +
      bienesDeUso.total_anterior,
    items: [bienesDeUso, intangible, ...activosNoCorrientes],
    color: 'yellow'
  });

  results.push({
    color: 'green',
    name: 'Activos totales',
    total: results[0].total + results[1].total,
    total_anterior: results[0].total_anterior + results[1].total_anterior,
    items: []
  });

  if (!cuentasByCategoria['Pasivos Corrientes']) {
    cuentasByCategoria['Pasivos Corrientes'] = [];
  }

  const allPasivosCorrientes = parameters.cuentasBce.filter((c) => c.es_pasivo && c.es_corriente);
  const pasivosCorrientes = cuentasByCategoria['Pasivos Corrientes'].map((c) => ({
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: c.monto,
    id: c.id.toString(),
    name: c.tipo_cuenta.descripcion,
    total_anterior: c.monto_anterior
  }));

  allPasivosCorrientes.forEach((cuenta) => {
    if (!pasivosCorrientes.some((ac) => ac.id === cuenta.id.toString())) {
      pasivosCorrientes.push({
        section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
        total: 0,
        id: cuenta.id.toString(),
        name: cuenta.descripcion,
        total_anterior: 0
      });
    }
  });

  results.push({
    color: 'yellow',
    name: 'Pasivos Corrientes',
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: pasivosCorrientes.reduce((acc, c) => acc + c.total, 0),
    total_anterior: pasivosCorrientes.reduce((acc, c) => acc + c.total_anterior, 0),
    items: pasivosCorrientes
  });

  if (!cuentasByCategoria['Pasivos No Corrientes']) {
    cuentasByCategoria['Pasivos No Corrientes'] = [];
  }

  const allPasivosNoCorrientes = parameters.cuentasBce.filter(
    (c) => c.es_pasivo && !c.es_corriente
  );

  const pasivosNoCorrientes = cuentasByCategoria['Pasivos No Corrientes'].map((c) => ({
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: c.monto,
    id: c.id.toString(),
    name: c.tipo_cuenta.descripcion,
    total_anterior: c.monto_anterior
  }));

  allPasivosNoCorrientes.forEach((cuenta) => {
    if (!pasivosNoCorrientes.some((ac) => ac.id === cuenta.id.toString())) {
      pasivosNoCorrientes.push({
        section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
        total: 0,
        id: cuenta.id.toString(),
        name: cuenta.descripcion,
        total_anterior: 0
      });
    }
  });

  results.push({
    color: 'yellow',
    name: 'Pasivos No Corrientes',
    section: BALANCE_DETALLE_SECTIONS.CUENTAS_BCE,
    total: pasivosNoCorrientes.reduce((acc, c) => acc + c.total, 0),
    total_anterior: pasivosNoCorrientes.reduce((acc, c) => acc + c.total_anterior, 0),
    items: pasivosNoCorrientes
  });

  results.push({
    color: 'green',
    name: 'Pasivos totales',
    total: results[3].total + results[4].total,
    total_anterior: results[3].total_anterior + results[4].total_anterior,
    items: []
  });

  results.push({
    color: 'yellow',
    name: 'PN',
    total: results[2].total - results[5].total,
    total_anterior: results[2].total_anterior - results[5].total_anterior,
    items: []
  });

  results.push({
    color: 'orange',
    name: 'Pasivos total + PN',
    total: results[5].total + results[6].total,
    total_anterior: results[5].total_anterior + results[6].total_anterior,
    items: []
  });

  return results;
};
