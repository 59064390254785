import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { TipoInversion } from '../../../interfaces/balance/parametros/tipoInversion';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de tipos de inversiones
 */
const getTiposInversion = async () => {
  return await axios
    .get<{ data: TipoInversion[] }>(`${PATH}/tipos-inversiones`)
    .then((response) => {
      return response.data.data;
    });
};

const TipoInversionService = {
  getTiposInversion
};

export default TipoInversionService;
