import axios from '../../../config/axios';
import { API_URL } from '../../../config';
import { TipoCuentaBce } from '../../../interfaces/balance/parametros/tipoCuentaBce';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de cuentas BCE
 */
const getTiposCuentasBce = async () => {
  return await axios
    .get<{ data: TipoCuentaBce[] }>(`${PATH}/tipos-cuentas-bce`)
    .then((response) => {
      return response.data.data;
    });
};

const TipoCuentasBceService = {
  getTiposCuentasBce
};

export default TipoCuentasBceService;
