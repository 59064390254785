import { API_URL } from '../../../config';

import axios from '../../../config/axios';

import type {
  CategoriaIngresoSaldo,
  SubcategoriaIngresoSaldo
} from '../../../interfaces/saldo/parametros/categoriasIngresosSaldo';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de categorias de ingresos vigentes
 */
const getCategoriasIngresosSaldo = async () => {
  return await axios
    .get<{ data: CategoriaIngresoSaldo[] }>(`${PATH}/categoria-ingreso-saldo`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API el listado de subcategorias de ingresos vigentes
 */
const getSubcategoriasIngresosSaldo = async () => {
  return await axios
    .get<{ data: SubcategoriaIngresoSaldo[] }>(`${PATH}/subcategoria-ingreso-saldo`)
    .then((response) => {
      return response.data.data;
    });
};

const CategoriasIngresosService = {
  getCategoriasIngresosSaldo,
  getSubcategoriasIngresosSaldo
};

export default CategoriasIngresosService;
