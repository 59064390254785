import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import PuertosService from '../../../services/puertos.service';
import type { RootState } from '../../store';

type Entity = Awaited<ReturnType<typeof PuertosService.getPuertosDelSistema>>[number];

export const fetchPuertosDelSistema = createAsyncThunk<Entity[]>(
  'paises/fetchPuertosDelSistemaStatus',
  (_params, api) => {
    const { puertos } = api.getState() as RootState;
    if (puertos.del_sistema.length) return Promise.resolve(puertos.del_sistema);
    return PuertosService.getPuertosDelSistema();
  }
);

interface PuertosState {
  loading: boolean;
  del_sistema: Entity[];
}

const initialState = {
  loading: true,
  del_sistema: []
} as PuertosState;

const puertosSlice = createSlice({
  name: 'puertos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPuertosDelSistema.fulfilled, (state, action) => {
      state.del_sistema = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPuertosDelSistema.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPuertosDelSistema.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const puertosActions = { ...puertosSlice.actions, fetchPuertosDelSistema };

export default puertosSlice.reducer;
