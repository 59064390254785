import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import { faAnchor, faBox, faFile, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { faMap, faShip, faBuilding, faBook, faSackDollar } from '@fortawesome/free-solid-svg-icons';

export const BuqueIcon = (props: any) => <FontAwesomeIcon icon={faShip} {...props} />;

export const PuertoIcon = (props: any) => <FontAwesomeIcon icon={faAnchor} {...props} />;

export const ViajeIcon = (props: any) => <FontAwesomeIcon icon={faMap} {...props} />;

export const ImportarIcon = (props: any) => <FontAwesomeIcon icon={faFile} {...props} />;

export const LoteIcon = (props: any) => <FontAwesomeIcon icon={faBox} {...props} />;

export const AgenteMaritimoIcon = (props: any) => <FontAwesomeIcon icon={faBuilding} {...props} />;

export const NavieraIcon = (props: any) => <FontAwesomeIcon icon={faBook} {...props} />;

export const ContableIcon = (props: any) => (
  <FontAwesomeIcon icon={faMagnifyingGlassDollar} {...props} />
);

export const BalancesIcon = (props: any) => <FontAwesomeIcon icon={faScaleBalanced} {...props} />;

export const SaldosIcon = (props: any) => <FontAwesomeIcon icon={faHandHoldingDollar} {...props} />;

export const PresupuestosIcon = (props: any) => <FontAwesomeIcon icon={faSackDollar} {...props} />;
