export const BALANCE_DETALLE_SECTIONS = {
  DETALLES: '0',
  CUENTAS_BCE: '1',
  INGRESOS: '2',
  GASTOS: '3',
  CAJAS_BANCOS: '4',
  MONEDAS_EXTRANJERAS: '5',
  INVERSIONES: '6',
  BIENES_DE_USO: '7',
  INTANGIBLES: '8',
  RESULTADOS_FINANCIEROS: '9'
};
