export const ROUTES = {
  HOMEPAGE_ROUTE: '/',

  VIAJES_ROUTE: '/estadias',
  BUQUES_ROUTE: '/buques',
  LOGIN_ROUTE: '/ingresar',
  LOTES_ROUTE: '/lotes',
  PUERTOS_ROUTE: '/puertos',
  PROFILE_ROUTE: '/perfil',
  LOTE_DETALLE_ROUTE: '/lotes/:loteId',
  VIAJES_DETALLE_ROUTE: '/estadias/:viajeId',
  PUERTO_DETALLE_ROUTE: '/puertos/:puertoId',
  FORGOT_PASSWORD_ROUTE: '/olvide-contraseña',
  RESET_PASSWORD_ROUTE: '/recuperar-contraseña',
  VIAJES_NEW_ROUTE: '/lotes/:loteId/estadias/nueva',
  IMPORTACION_LOTES_ROUTE: '/importacion',
  VIAJES_EDIT_ROUTE: '/lotes/:loteId/estadias/:viajeId/editar',
  LOTE_VIAJES_DETALLE_ROUTE: '/lotes/:loteId/estadias/:viajeId',
  CONTENIDO_CONTEINERIZADO_DETALLE_ROUTE:
    '/lotes/:loteId/estadias/:viajeId/operaciones/:operacionId/contenido-conteinerizado/:contenidoId',

  BALANCES_ROUTES: '/contable/balances',
  BALANCES_NEW_ROUTE: '/contable/balances/nuevo',
  BALANCES_EDIT_ROUTE: '/contable/balances/:balanceId/editar',
  BALANCES_DETAIL_ROUTE: '/contable/balances/:balanceId',
  BALANCES_IMPORT_ROUTE: '/contable/importar-balances',

  PRESUPUESTOS_ROUTES: '/contable/presupuestos',
  PRESUPUESTOS_NEW_ROUTE: '/contable/presupuestos/nuevo',
  PRESUPUESTOS_EDIT_ROUTE: '/contable/presupuestos/:presupuestoId/editar',
  PRESUPUESTOS_DETAIL_ROUTE: '/contable/presupuestos/:presupuestoId',
  PRESUPUESTOS_IMPORT_ROUTE: '/contable/importar-presupuestos',

  SALDOS_ROUTES: '/contable/saldos',
  SALDOS_NEW_ROUTE: '/contable/saldos/nuevo',
  SALDOS_EDIT_ROUTE: '/contable/saldos/:saldoId/editar',
  SALDOS_DETAIL_ROUTE: '/contable/saldos/:saldoId',
  SALDOS_IMPORT_ROUTE: '/contable/importar-saldos'
};
