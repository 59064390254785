import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';
import VariablesPresupuestoService from '../../../../services/presupuesto/parametros/variablesPresupuesto';

type Entity = Awaited<
  ReturnType<typeof VariablesPresupuestoService.getVariablesPresupuesto>
>[number];

export const fetchVariablesPresupuesto = createAsyncThunk<Entity[]>(
  'variablesPresupuesto/fetchVariablesPresupuesto',
  (_params, api) => {
    const { variablesPresupuesto } = api.getState() as RootState;
    if (variablesPresupuesto.entities.length) return Promise.resolve(variablesPresupuesto.entities);
    return VariablesPresupuestoService.getVariablesPresupuesto();
  }
);

interface VariablesPresupuestoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  areas: [],
  entities: [],
  loading: true
} as VariablesPresupuestoState;

const variablesPresupuestoSlice = createSlice({
  name: 'variablesPresupuesto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVariablesPresupuesto.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchVariablesPresupuesto.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchVariablesPresupuesto.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const variablesPresupuestoActions = {
  ...variablesPresupuestoSlice.actions,
  fetchVariablesPresupuesto
};

export default variablesPresupuestoSlice.reducer;
