import { groupBy } from 'lodash';

// Interface
import type { GastoBalance, IngresoBalance } from '../../../../interfaces/balance/balance';
import type { TipoBienUsoBalance } from '../../../../interfaces/balance/tipoBienUsoBalance';
import { TipoResultadoFinancieroBalance } from '../../../../interfaces/balance/tipoResultadoFinancieroBalance';
import { BALANCE_DETALLE_SECTIONS } from '../../../../pages/balances/detalle/constants/sections';

export interface BalanceEstadoResultadoChild {
  id: string;
  name: string;
  total: number;
  section: string;
  vertical: number;
  total_anterior: number;
}

export interface BalanceEstadoResultado {
  name: string;
  total: number;
  vertical: number;
  section?: string;
  total_anterior: number;
  items: BalanceEstadoResultadoChild[];
  color: 'green' | 'yellow' | 'orange';
}

const getGastosByCategoria = (gastos: GastoBalance[]) => {
  const ordinarios = gastos.filter((g) => g.gasto.categoria.descripcion === 'Gastos ordinarios');
  const gastosByArea = groupBy(ordinarios, (g) => g.area.id);
  const gastosByAreaData = Object.keys(gastosByArea).map((key) => {
    const area = gastosByArea[key][0].area;
    const gastos = gastosByArea[key];

    return {
      area,
      gastos
    };
  });

  const gastosOperativos = gastosByAreaData.filter(
    (g) => g.area.descripcion === 'Operativos / Funcionamiento'
  );

  const gastosComercializacion = gastosByAreaData.filter(
    (g) => g.area.descripcion === 'Comercialización / Financieros'
  );

  const gastosAdministrativos = gastosByAreaData.filter(
    (g) => g.area.descripcion === 'Administración'
  );

  const gastosExtraordinarios = gastos.filter(
    (g) => g.gasto.categoria.descripcion === 'Gastos extraordinarios'
  );

  return {
    gastosOperativos,
    gastosAdministrativos,
    gastosComercializacion,
    gastosExtraordinarios
  };
};

const getIngresosByCategoria = (ingresos: IngresoBalance[]) => {
  const ingresosByCategoria = groupBy(ingresos, (i) => i.ingreso.categoria.id);
  const ingresosByCategoriaData = Object.keys(ingresosByCategoria).map((key) => {
    const categoria = ingresosByCategoria[key][0].ingreso.categoria;
    const ingresos = ingresosByCategoria[key];

    return {
      categoria,
      ingresos
    };
  });

  const facturacion = ingresosByCategoriaData.filter(
    (i) => i.categoria.descripcion === 'Facturación'
  );

  const otrosIngresos = ingresosByCategoriaData.filter(
    (i) => i.categoria.descripcion === 'Otros ingresos ordinarios'
  );

  const extraordinarios = ingresosByCategoriaData.filter(
    (i) => i.categoria.descripcion === 'Ingresos extraordinarios'
  );

  return {
    facturacion,
    otrosIngresos,
    extraordinarios
  };
};

const getBienesDeUso = (bienes: TipoBienUsoBalance[]) => {
  return bienes.filter(
    (b) => b.bien_de_uso.categoria.descripcion === 'Depreciación y Amortización'
  );
};

export const getEstadoResultado = (
  ingresos: { data: IngresoBalance[] },
  gastos: { data: GastoBalance[] },
  bienUso: { data: TipoBienUsoBalance[] },
  resultadosFinancieros: { data: TipoResultadoFinancieroBalance[] }
): BalanceEstadoResultado[] => {
  const results: BalanceEstadoResultado[] = [];

  const bienesUsoData = getBienesDeUso(bienUso.data);
  const gastosData = getGastosByCategoria(gastos.data);
  const ingresosData = getIngresosByCategoria(ingresos.data);

  // Utilidad bruta
  const facturacion = {
    name: 'Facturación',
    id: ingresosData?.facturacion[0]?.categoria?.id?.toString() || '0',
    total: ingresosData.facturacion[0]?.ingresos?.reduce((acc, i) => acc + i.monto, 0) || 0,
    total_anterior:
      ingresosData.facturacion[0]?.ingresos?.reduce((acc, i) => acc + i.monto_anterior, 0) || 0,
    section: BALANCE_DETALLE_SECTIONS.INGRESOS,
    vertical: 100
  };

  const gastosOperativos = {
    name: 'Gastos Operativos / Funcionamiento',
    id: '1',
    total: gastosData.gastosOperativos[0]?.gastos.reduce((acc, g) => acc + g.monto, 0),
    total_anterior: gastosData.gastosOperativos[0]?.gastos.reduce(
      (acc, g) => acc + g.monto_anterior,
      0
    ),
    section: BALANCE_DETALLE_SECTIONS.GASTOS,
    vertical: 0
  };

  gastosOperativos.vertical = gastosOperativos.total_anterior / facturacion.total_anterior;

  results.push({
    color: 'yellow',
    name: 'Utilidad bruta',
    total: facturacion.total - gastosOperativos.total,
    total_anterior: facturacion.total_anterior - gastosOperativos.total_anterior,
    vertical:
      (facturacion.total_anterior - gastosOperativos.total_anterior) /
      (facturacion.total_anterior || 1),
    items: [facturacion, gastosOperativos]
  });

  // Gastos

  const gastosAdministracion = {
    name: 'Administración',
    id: gastosData.gastosAdministrativos[0]?.area.id.toString(),
    total: gastosData.gastosAdministrativos[0]?.gastos.reduce((acc, g) => acc + g.monto, 0) || 0,
    total_anterior:
      gastosData.gastosAdministrativos[0]?.gastos.reduce((acc, g) => acc + g.monto_anterior, 0) ||
      0,
    section: BALANCE_DETALLE_SECTIONS.GASTOS,
    vertical: 0
  };

  gastosAdministracion.vertical = gastosAdministracion.total_anterior / facturacion.total_anterior;

  const gastosComercializacion = {
    name: 'Comercialización / Financieros',
    id: gastosData.gastosComercializacion[0]?.area.id.toString(),
    total: gastosData.gastosComercializacion[0]?.gastos.reduce((acc, g) => acc + g.monto, 0) || 0,
    total_anterior:
      gastosData.gastosComercializacion[0]?.gastos.reduce((acc, g) => acc + g.monto_anterior, 0) ||
      0,
    section: BALANCE_DETALLE_SECTIONS.GASTOS,
    vertical: 0
  };

  gastosComercializacion.vertical =
    gastosComercializacion.total_anterior / facturacion.total_anterior;

  const deprecionyAmortizacion = {
    name: 'Depreciación y Amortización',
    id: '1',
    total: bienesUsoData.reduce((acc, b) => acc + b.monto, 0),
    total_anterior: bienesUsoData.reduce((acc, b) => acc + b.monto_anterior, 0),
    section: BALANCE_DETALLE_SECTIONS.BIENES_DE_USO,
    vertical: 0
  };

  deprecionyAmortizacion.vertical =
    deprecionyAmortizacion.total_anterior / facturacion.total_anterior;

  results.push({
    color: 'yellow',
    name: 'Gastos ordinarios',
    section: BALANCE_DETALLE_SECTIONS.INGRESOS,
    total: gastosAdministracion.total + gastosComercializacion.total + deprecionyAmortizacion.total,
    total_anterior:
      gastosAdministracion.total_anterior +
      gastosComercializacion.total_anterior +
      deprecionyAmortizacion.total_anterior,
    vertical:
      (gastosAdministracion.total_anterior +
        gastosComercializacion.total_anterior +
        deprecionyAmortizacion.total_anterior) /
      facturacion.total_anterior,
    items: [gastosAdministracion, gastosComercializacion, deprecionyAmortizacion]
  });

  results.push({
    color: 'yellow',
    name: 'Ganancia / Pérdida operativa EBIT',
    total: results[0].total - results[1].total,
    total_anterior: results[0].total_anterior - results[1].total_anterior,
    vertical: (results[0].total_anterior - results[1].total_anterior) / facturacion.total_anterior,
    items: []
  });

  results.push({
    color: 'yellow',
    section: BALANCE_DETALLE_SECTIONS.INGRESOS,
    name: 'Otros ingresos ordinarios',
    total: ingresosData.otrosIngresos.reduce(
      (acc, i) => acc + i.ingresos.reduce((acc, i) => acc + i.monto, 0),
      0
    ),
    total_anterior: ingresosData.otrosIngresos.reduce(
      (acc, i) => acc + i.ingresos.reduce((acc, i) => acc + i.monto_anterior, 0),
      0
    ),
    vertical:
      ingresosData.otrosIngresos.reduce(
        (acc, i) => acc + i.ingresos.reduce((acc, i) => acc + i.monto_anterior, 0),
        0
      ) / facturacion.total_anterior,
    items: []
  });

  results.push({
    color: 'yellow',
    name: 'EBITDA',
    total: results[2].total + results[3].total + deprecionyAmortizacion.total,
    total_anterior:
      results[2].total_anterior + results[3].total_anterior + deprecionyAmortizacion.total_anterior,
    vertical:
      (results[2].total_anterior +
        results[3].total_anterior +
        deprecionyAmortizacion.total_anterior) /
      facturacion.total_anterior,
    items: []
  });

  resultadosFinancieros.data.forEach((r) => {
    results.push({
      color: 'green',
      section: BALANCE_DETALLE_SECTIONS.RESULTADOS_FINANCIEROS,
      name: r.tipo.descripcion,
      total: r.monto,
      total_anterior: r.monto_anterior,
      vertical: r.monto_anterior / facturacion.total_anterior,
      items: []
    });
  });

  results.push({
    color: 'green',
    section: BALANCE_DETALLE_SECTIONS.INGRESOS,
    name: ingresosData?.extraordinarios[0]?.categoria.descripcion || 'Ingresos extraordinarios',
    total: ingresosData.extraordinarios[0]?.ingresos.reduce((acc, i) => acc + i.monto, 0) || 0,
    total_anterior:
      ingresosData.extraordinarios[0]?.ingresos.reduce((acc, i) => acc + i.monto_anterior, 0) || 0,
    vertical:
      ingresosData.extraordinarios[0]?.ingresos.reduce((acc, i) => acc + i.monto_anterior, 0) /
      facturacion.total_anterior,
    items: []
  });

  results.push({
    color: 'green',
    name: 'Egresos extraordinarios',
    section: BALANCE_DETALLE_SECTIONS.GASTOS,
    total: gastosData.gastosExtraordinarios.reduce((acc, i) => acc + i.monto, 0),
    total_anterior: gastosData.gastosExtraordinarios.reduce((acc, i) => acc + i.monto_anterior, 0),
    vertical:
      gastosData.gastosExtraordinarios.reduce((acc, i) => acc + i.monto_anterior, 0) /
      facturacion.total_anterior,
    items: []
  });

  const items = [
    'Ganancia / Pérdida operativa EBIT',
    'Otros ingresos ordinarios',
    'Ingresos extraordinarios',
    'Resultados financieros y por tenencia',
    'Resultados financieros y por tenencia (neto de amortización)'
  ];

  const filteredItems = results.filter((r) => items.includes(r.name));

  results.push({
    color: 'orange',
    name: 'Superávit / Déficit del ejercicio',
    total: filteredItems.reduce((acc, r) => acc + r.total, 0),
    total_anterior: filteredItems.reduce((acc, r) => acc + r.total_anterior, 0),
    vertical:
      filteredItems.reduce((acc, r) => acc + r.total_anterior, 0) / facturacion.total_anterior,
    items: []
  });

  return results;
};
