// Service
import LotesService from '../../../services/lote/lotes.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { LoteDetalle } from '../../../interfaces/lote/lote';
import type { BuqueNovedad } from '../../../interfaces/lote/buques';

const initialState: {
  error: string;
  loading: boolean;
  lote: LoteDetalle | null;
  buquesNovedades: BuqueNovedad[];
} = {
  error: '',
  lote: null,
  loading: true,
  buquesNovedades: []
};

export const fetchLote = createAsyncThunk<LoteDetalle, number>(
  'lote/fetchLoteStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await LotesService.getLote(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchLoteBuquesNovedades = createAsyncThunk<BuqueNovedad[], number>(
  'lote/fetchLoteBuquesNovedadesStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await LotesService.getBuqueNovedades(id, { perPage: 1000 }).then((data) => data.data);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const loteDetalleSlice = createSlice({
  name: 'loteDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.lote = null;
      state.loading = true;
      state.buquesNovedades = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLote.fulfilled, (state, action) => {
      state.lote = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchLote.pending, (state, action) => {
      if (!state.lote || state.lote.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchLote.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });

    builder.addCase(fetchLoteBuquesNovedades.fulfilled, (state, action) => {
      state.buquesNovedades = action.payload;
    });

    builder.addCase(fetchLoteBuquesNovedades.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const loteDetalleActions = {
  fetchLote,
  fetchLoteBuquesNovedades,
  ...loteDetalleSlice.actions
};

export default loteDetalleSlice.reducer;
