import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import TipoMonedaExtranjeraService from '../../../../services/balance/parametros/monedaExtranjera.service';

type Entity = Awaited<ReturnType<typeof TipoMonedaExtranjeraService.getMonedasExtranjeras>>[number];

export const fetchMonedasExtranjeras = createAsyncThunk<Entity[]>(
  'tiposMonedasExtranjeras/fetchMonedasExtranjeras',
  (_params, api) => {
    const { tiposMonedasExtranjeras } = api.getState() as RootState;
    if (tiposMonedasExtranjeras.entities.length)
      return Promise.resolve(tiposMonedasExtranjeras.entities);
    return TipoMonedaExtranjeraService.getMonedasExtranjeras();
  }
);

interface TiposMonedasExtranjerasState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposMonedasExtranjerasState;

const tiposMonedaExtranjeraSlice = createSlice({
  name: 'tiposMonedasExtranjeras',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMonedasExtranjeras.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchMonedasExtranjeras.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMonedasExtranjeras.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const TiposMonedasExtranjerasActions = {
  ...tiposMonedaExtranjeraSlice.actions,
  fetchMonedasExtranjeras
};

export default tiposMonedaExtranjeraSlice.reducer;
