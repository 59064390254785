import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AgenteMaritimoService from '../../../../services/lote/parametros/agenteMaritimo.service';
import type { RootState } from '../../../store';

type Entity = Awaited<ReturnType<typeof AgenteMaritimoService.getAgentesMaritimos>>[number];

export const fetchAgentesMaritimos = createAsyncThunk<Entity[]>(
  'tiposBuque/fetchAgentesMaritimoStatus',
  (_params, api) => {
    const { agenteMaritimos } = api.getState() as RootState;
    if (agenteMaritimos.entities.length) return Promise.resolve(agenteMaritimos.entities);
    return AgenteMaritimoService.getAgentesMaritimos();
  }
);

interface AgenteMaritimoState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as AgenteMaritimoState;

const agenteMaritimoSlice = createSlice({
  name: 'agentesMaritimos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgentesMaritimos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAgentesMaritimos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAgentesMaritimos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const agenteMaritimoActions = { ...agenteMaritimoSlice.actions, fetchAgentesMaritimos };

export default agenteMaritimoSlice.reducer;
