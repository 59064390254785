// Service
import PuertosService from '../../../services/puertos.service';

// Redux
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { PuertoDetalle } from '../../../interfaces/puertos';

const initialState: { puerto: PuertoDetalle | null; loading: boolean; error: string } = {
  error: '',
  puerto: null,
  loading: true
};

export const fetchPuerto = createAsyncThunk<PuertoDetalle, number>(
  'paises/fetchPuertoStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await PuertosService.getPuerto(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const puertoDetalleSlice = createSlice({
  name: 'puertoDetalle',
  initialState,
  reducers: {
    increasePuertoTerminales(state) {
      if (state.puerto) {
        state.puerto.cantidad_terminales = state.puerto.cantidad_terminales + 1;
      }
    },
    clearError(state) {
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPuerto.fulfilled, (state, action) => {
      state.puerto = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPuerto.pending, (state) => {
      state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchPuerto.rejected, (state, error) => {
      state.loading = false; // @ts-ignore
      state.error = error.payload || '500';
    });
  }
});

export const puertoDetalleActions = { ...puertoDetalleSlice.actions, fetchPuerto };

export default puertoDetalleSlice.reducer;
